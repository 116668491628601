import { ILoginConfig } from "@plum/plum-nav";
import React, { useEffect } from "react";
import { createContext, useContext, useState, useMemo } from "react";

type LoginContextProps = {
  config?: ILoginConfig;
  children: React.ReactNode;
};

type LoginState = {
  isLoggedIn: boolean;
  isRegistrationComplete: boolean;
};

type LoginActions = {
  performLogin: () => void;
  performLogout: () => void;
  performRegister: () => void;
};

export const LoginStateContext = createContext<LoginState>({
  isLoggedIn: false,
  isRegistrationComplete: false,
});
LoginStateContext.displayName = "LoginStateContext";

export const LoginActionsContext = createContext<LoginActions>({
  performLogin: () => {},
  performLogout: () => {},
  performRegister: () => {},
});

LoginActionsContext.displayName = "LoginActionsContext";

export const useLoginStateContext = () => useContext(LoginStateContext);

export const useLoginActionsContext = () => useContext(LoginActionsContext);

export const LoginProvider: React.FC<LoginContextProps> = ({ config, children }) => {
  const isAuthenticated = Boolean(config?.userInfo?.isAuthenticated);

  const [state, setState] = useState<LoginState>({
    isLoggedIn: isAuthenticated,
    isRegistrationComplete: false,
  });

  const actions = useMemo<LoginActions>(
    () => ({
      performLogin: () => {
        setState((s) => ({
          ...s,
          isLoggedIn: true,
        }));
      },
      performRegister: () => {
        setState((s) => ({
          ...s,
          isLoggedIn: true,
          isRegistrationComplete: true,
        }));
      },
      performLogout: () => {
        setState((s) => ({
          ...s,
          isLoggedIn: false,
        }));
      },
    }),
    []
  );

  // Keep `state.isLoggedIn` in sync with `config.userInfo.isAuthenticated`
  useEffect(() => {
    isAuthenticated ? actions.performLogin() : actions.performLogout();
  }, [actions, isAuthenticated]);

  return (
    <LoginStateContext.Provider value={state}>
      <LoginActionsContext.Provider value={actions}>{children}</LoginActionsContext.Provider>
    </LoginStateContext.Provider>
  );
};
