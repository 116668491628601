import { tv } from "tailwind-variants";
export const SAccountButton = tv({
  base: [
    "items-center",
    "cursor-pointer",
    "flex",
    "rounded-full",
    "mr-4",
    "h-10",
    "w-10",
    "bg-white",
    "pl-2",
    "pr-1",
    "rounded-xl",
    "h-10",
    "w-auto",
    "relative",
    "transition-opacity",
    "transition-colors",
    "duration-150",
    "outline-none",
    "ease-out-cubic", // doesnt work atm
  ],
  variants: {
    theme: {
      default: "bg-white hover:bg-gray-100 focus:bg-gray-100",
      transparent: "bg-white opacity-80 hover:opacity-100 focus:opacity-100",
      cream: "bg-cream",
    },
    isLoggedIn: {
      true: "bg-white",
      false: "bg-white",
    },
  },
});
