import React from "react";
import { IconBurgerMenu } from "@plum/plum-ui-next";
import { IUserInfo } from "@plum/plum-nav/services";
import { SAccountButton } from "./AccountButton.styles";
import { UserAvatar } from "./UserAvatar";
import { LoggedOutLabel } from "./LoggedOutLabel";

export interface IAccountButton {
  cdnUrl?: string;
  isLink?: boolean;
  isLoggedIn: boolean;
  onClick?: () => void;
  userInfo?: Partial<IUserInfo>;
  theme?: "default" | "cream" | "transparent";
}

export const AccountButton = ({
  cdnUrl,
  isLink = false,
  isLoggedIn = false,
  onClick,
  userInfo,
  theme = "default",
}: IAccountButton) => {
  const label = isLoggedIn ? "Log out" : "Log in";

  return isLink ? (
    <a
      href="/account/login"
      aria-label={label}
      data-track-id={`header__account-button__${isLoggedIn ? "logged-in" : "logged-out"}`}
      className={SAccountButton({ isLoggedIn, theme })}
    >
      <AccountButtonContent
        isLoggedIn={isLoggedIn}
        userInfo={userInfo}
        cdnUrl={cdnUrl}
        theme={theme}
      />
    </a>
  ) : (
    <button
      onClick={onClick}
      aria-label={label}
      data-track-id={`header__account-button__${isLoggedIn ? "logged-in" : "logged-out"}`}
      className={SAccountButton({ isLoggedIn, theme })}
    >
      <AccountButtonContent
        isLoggedIn={isLoggedIn}
        userInfo={userInfo}
        cdnUrl={cdnUrl}
        theme={theme}
      />
    </button>
  );
};

AccountButton.displayName = "AccountButton";

const AccountButtonContent = ({ isLoggedIn, userInfo, cdnUrl, theme }) => (
  <>
    <IconBurgerMenu className="mr-1" />
    {isLoggedIn ? (
      <UserAvatar
        name={userInfo?.name}
        src={userInfo?.photoUrl}
        cdnUrl={cdnUrl}
        isTransparent={theme === "transparent"}
      />
    ) : (
      <LoggedOutLabel />
    )}
  </>
);
