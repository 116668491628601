import React from "react";
import { ILoginConfig } from "@plum/plum-nav";
import { LoginProvider } from "./LoginContext";
import { tv } from "tailwind-variants";
export interface IHeaderNavWrapper extends React.PropsWithChildren {
  // Config type is optional because some page HeaderNav don't include LoginMenu
  config?: ILoginConfig;
  theme: "default" | "cream" | "transparent";
  hasShadow: boolean;
}

export const HeaderNavWrapper = React.forwardRef<HTMLElement, IHeaderNavWrapper>(
  ({ children, config, hasShadow, theme, ...props }, ref) => (
    <LoginProvider config={config}>
      <header
        className={SHeaderNav({
          hasShadow,
          theme,
        })}
        {...props}
        ref={ref}
      >
        {children}
      </header>
    </LoginProvider>
  )
);

HeaderNavWrapper.displayName = "HeaderNavWrapper";
const SHeaderNav = tv({
  base: ["absolute", "z-[5]", "w-full", "grid", "grid-cols-3", "items-center", "h-16", "sm:mt-1"],

  variants: {
    hasShadow: {
      true: ["shadow-header"],
      false: ["shadow-none"],
    },
    theme: {
      default: ["bg-system-white"],
      cream: ["bg-brand-cream"],
      transparent: ["bg-opacity-0"],
    },
  },
});
