import React from "react";
import { Text } from "@plum/ui-core";

import { UserAvatar } from "./UserAvatar";

export const LoggedInTopBar: React.FC<{ name?: string; src?: string | null; cdnUrl?: string }> = ({
  name,
  src,
  cdnUrl,
}) => {
  return (
    <>
      <div className="flex justify-center">
        <Text _bold>{name}</Text>
      </div>
      {/* do not show if there is not photo, as name is shown above already */}
      {src && <UserAvatar cdnUrl={cdnUrl} name={name} src={src} />}
    </>
  );
};
