"use client";

import { ApolloProvider } from "@apollo/client";

import apolloClient from "@/gql/client";

import { UserProvider } from "@/data/UserProvider";
import { ModalsContainer } from "@/components/modals";

import { I18nProvider } from "./i18n";

export function Providers({ children }) {
  return (
    <I18nProvider>
      <ApolloProvider client={apolloClient}>
        <UserProvider>
          {children}

          <ModalsContainer />
        </UserProvider>
      </ApolloProvider>
    </I18nProvider>
  );
}
