import React from "react";
import { useTranslation } from "react-i18next";
import { SHeaderNavButton } from "./HeaderNavButton.styles";
import { UserSettingsModal } from "@plum/ui-modals";

interface ICurrencyAndLangButtonProps {
  rounded?: boolean;
  language?: string;
  currencyCode?: string;
}

export const CurrencyAndLangButton = ({
  rounded,
  language,
  currencyCode,
}: ICurrencyAndLangButtonProps) => {
  const { t } = useTranslation();

  return (
    <UserSettingsModal
      language={"EN"}
      currencyCode={currencyCode || "GBP"}
      trigger={
        <button
          className={SHeaderNavButton({
            rounded,
            className: "max-lg:hidden",
          })}
          data-track-id="header-nav__user-settings-link"
          aria-label={t("common:nav.topBar.userSettings.buttonAriaLabel")}
        >
          <span>{`${getLanguageFromLocale(language || "en-GB").toUpperCase()}`}</span>
          <div className="mx-2 h-4 bg-gray-900 self-center w-[1px]"></div>
          <span>{`${getCurrencySymbolFromCode(currencyCode || "GBP")}`}</span>
        </button>
      }
    />
  );
};

export const getCurrencySymbolFromCode = (currencyCode: string) => {
  try {
    return Number(0)
      .toLocaleString("en", {
        style: "currency",
        currency: currencyCode,
        maximumSignificantDigits: 1,
      })
      .replace("0", "")
      .trim();
  } catch (e) {
    return "";
  }
};

export const getLanguageFromLocale = (language: string) => {
  if (language) {
    return language.split("-")[0];
  }

  return "";
};

CurrencyAndLangButton.displayName = "CurrencyAndLangButton";
