"use client";

import { FooterInfo, FooterUserSettings, FooterCopyright, TrustpilotReviews } from "@plum/ui-nav";

import { useUserContext } from "@/data/UserProvider";
import { CondeNastReadersLogo } from "../PressLogos/logos/conde-nast-traveler-readers";

export function FooterContainer({ hideLogos = false }) {
  const [{ user }] = useUserContext();

  return (
    <footer>
      <FooterInfo>
        {!hideLogos && (
          <div className="flex gap-8 items-start">
            <CondeNastReadersLogo className="-mt-1 w-[104px] h-[104px]" year="2024" />{" "}
            <TrustpilotReviews />
          </div>
        )}
        <FooterUserSettings language={user.language} currencyCode={user.currencyCode} />
      </FooterInfo>
      <FooterCopyright />
    </footer>
  );
}
