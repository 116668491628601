import React from "react";
import { IUserInfo } from "@plum/plum-nav/services";

const HostView = React.lazy(() => import("./HostView/HostView"));
const GuestView = React.lazy(() => import("./GuestView/GuestView"));

interface ILoggedInContent {
  currentPathname: string;
  onLogout: () => void;
  user?: Partial<IUserInfo>;
}

export const LoggedInContent = ({ currentPathname, user, onLogout }: ILoggedInContent) => {
  if (!user?.isAuthenticated) {
    return null;
  }

  if (hasHostRole(user.roles)) {
    return <HostView currentPathname={currentPathname} onLogout={onLogout} />;
  }

  return <GuestView currentPathname={currentPathname} onLogout={onLogout} />;
};

export const hasHostRole = (roles?: string[]): boolean =>
  roles ? roles.map((r) => r.toLowerCase()).includes("host") : false;
