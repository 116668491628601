import { ILoginConfig, HelpButton } from "@plum/plum-nav";
import { IUserInfo } from "@plum/plum-nav/services";
import * as React from "react";
import { LoginMenu } from "./LoginMenu";
import { HeaderNavWrapper } from "./HeaderNavWrapper";
import { IconLogo } from "@plum/ui-icons";
import { BecomeAHostButton } from "./BecomeAHostButton";
import { CurrencyAndLangButton } from "./CurrencyAndLangButton";

export type MenuName = "burger" | "login";

export interface IHeaderNavProps {
  /**
   * Login configuration object
   *
   * Holds state or login config
   */
  config: ILoginConfig;
  /**
   * CSRF token for auth forms
   */
  token?: string;
  /**
   * Optional themes
   *
   * Cream: dark foreground with cream background
   */
  theme?: "default" | "cream" | "transparent";
  /**
   * Enable/disable the box-shadow
   */
  hasShadow?: boolean;
  /**
   * DOM ref for HeaderNav
   */
  ref?: React.Ref<HTMLElement>;
  /**
   * Hide become a button from header
   */
  hideBecomeHost?: boolean;
  /**
   * Hide currency and language button from header
   */
  hideCurrencyLang?: boolean;
  /**
   * Hide need help button from header
   */
  hideHelp?: boolean;
  /**
   * Hide user menu from header
   */
  hideUserMenu?: boolean;
  /**
   * Fired when a user logs in
   */
  onLogin: (user: IUserInfo) => void;
  /**
   * Fired when a user logs out
   */
  onLogout?: () => void;
}

/**
 *
 * TODO: refactor (at some point) prop drilling into a NavContext (export the context to facilitate consumer changing data)
 */
export const HeaderNav = React.forwardRef<HTMLElement, IHeaderNavProps>(
  (
    {
      config,
      token,
      theme = "default",
      hasShadow = true,
      hideBecomeHost,
      hideCurrencyLang = false,
      hideHelp = false,
      hideUserMenu = false,
      onLogin,
      onLogout,
    },
    ref
  ) => {
    return (
      <HeaderNavWrapper
        ref={ref}
        config={config}
        theme={theme}
        hasShadow={hasShadow}
        data-track-id="plum-header"
      >
        <div></div>
        <div className="w-full flex items-center justify-center">
          <a
            aria-haspopup="false"
            aria-label="Plum Guide"
            role="button"
            tabIndex={0}
            href={"/"}
            data-track-id="header-nav__logo"
            className="w-fit h-full"
          >
            <IconLogo _block className={`w-[150px] ${theme === "transparent" ? "invert" : ""}`} />
          </a>
        </div>

        <div className="flex justify-end h-full items-center">
          {!hideBecomeHost && <BecomeAHostButton rounded={theme === "transparent"} />}
          {!hideHelp && <HelpButton rounded={theme === "transparent"} />}
          {!hideCurrencyLang && (
            <CurrencyAndLangButton
              rounded={theme === "transparent"}
              language={config.userInfo?.language}
              currencyCode={config.userInfo?.currencyCode}
            />
          )}

          {!hideUserMenu && (
            <LoginMenu
              config={config}
              token={token}
              onLogin={onLogin}
              onLogout={onLogout}
              data-track-id="nav-login-button"
              isTransparent={theme === "transparent"}
              theme={theme}
            />
          )}
        </div>
      </HeaderNavWrapper>
    );
  }
);

HeaderNav.displayName = "HeaderNav";
