import { Avatar } from "@plum/plum-ui-next";
import React from "react";

export const UserAvatar = ({
  name,
  src,
  isTransparent,
  cdnUrl,
}: {
  name?: string;
  src?: string | null;
  isTransparent?: boolean;
  cdnUrl?: string;
}) => (
  <Avatar
    size={src ? "tiny" : "small"}
    name={name || ""}
    src={src ? cdnUrl + src : undefined}
    css={{ border: isTransparent ? "none" : "initial", fontWeight: 500 }}
  />
);
