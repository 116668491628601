import { tv } from "tailwind-variants";

export const SHeaderNavButton = tv({
  base: [
    "outline-none",
    "inline-flex",
    "items-center",
    "cursor-pointer",
    "font-sans",
    "font-medium",
    "justify-center",
    "px-4",
    "mr-4",
    "relative",
    "transition-all",
    "rounded-none",
    "min-w-32",
    "h-10",
  ],
  variants: {
    rounded: {
      true: ["rounded-xl", "bg-opacity-80", "hover:bg-white", "focus:bg-white", "bg-gray-150"],
      false: [
        "h-full",
        "w-32",
        "after:bg-yellow-primary",
        "after:h-[2px]",
        "after:opacity-0",
        "after:w-full",
        "after:absolute",
        "after:bottom-0",
        "after:left-0",
        "after:transition-all",
        "after:ease-out-cubic",
        "after:z-[-1]",
        "hover:after:opacity-100",
        "focus:after:opacity-100",
      ],
    },
  },
});
/* export const SHeaderNavButtonOld = styled("button", {
  reset: true,
  alignItems: "center",
  cursor: "pointer",
  display: "inline-flex",
  fontFamily: "$sans",
  fontWeight: 500,
  justifyContent: "center",
  paddingX: "$16",
  marginRight: "$16",
  position: "relative",
  transition: "150ms background-color ease-out",

  variants: {
    rounded: {
      true: {
        borderRadius: "$pill",
        height: "$40",

        "&&": {
          backgroundColor: rgba("system-white", 0.8),
        },

        ...multipleSelectors(["hover", "keyboardFocus"], {
          backgroundColor: rgba("system-white", 1),
        }),

        active: {
          backgroundColor: "$neutrals-3",
        },
      },
      false: {
        height: "100%",
        minWidth: "$32",

        "&::after": {
          backgroundColor: "$brand-yellow-primary",
          bottom: 0,
          content: "''",
          height: 2,
          left: 0,
          opacity: 0,
          position: "absolute",
          transition: "150ms ease-out",
          width: "100%",
          zIndex: -1,
        },

        ...multipleSelectors(["keyboardFocus", "hover"], {
          "&::after": {
            opacity: 1,
          },
        }),
      },
    },
  },
});
 */
