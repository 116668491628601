import React from "react";
import { useTranslation } from "react-i18next";
import { SHeaderNavButton } from "./HeaderNavButton.styles";

interface IBecomeAHostButtonProps {
  rounded?: boolean;
}

export const BecomeAHostButton = ({ rounded }: IBecomeAHostButtonProps) => {
  const { t } = useTranslation();

  return (
    <a
      href="/become-a-host"
      target="_blank"
      data-track-id="header-nav__becomeHost"
      className={SHeaderNavButton({ rounded, className: "max-lg:hidden" })}
    >
      {t("common:nav.topBar.becomeHost")}
    </a>
  );
};

BecomeAHostButton.displayName = "BecomeAHostButton";
