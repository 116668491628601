"use client";

import Script from "next/script";
import { env } from "@/env.mjs";

declare let TrackJS: { install: (options: { token: string; application: string }) => void };

export const TrackJSClientComponent = () => (
  <Script
    strategy="afterInteractive"
    src="https://cdn.trackjs.com/agent/v3/latest/t.js"
    onLoad={() => {
      window.TrackJS &&
        TrackJS.install({
          token: `${env.NEXT_PUBLIC_TRACKJS_TOKEN}`,
          application: `${env.NEXT_PUBLIC_TRACKJS_APPNAME}`,
        });
      console.log("TrackJS installed");
    }}
  />
);
