"use client";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalEvents } from "@plum/constants";
import { useListen } from "@plum/ui-events";

import {
  UserSettingsModal,
  IUserSettingsModalProps,
  useModal,
  useModalActions,
  NewsletterModal,
} from "@plum/plum-modals";
import { useDialogManager } from "@plum/plum-ui-next";

import { env } from "@/env.mjs";

import { useUserContext } from "@/data/UserProvider";
import { useCampaign } from "@/hooks/useCampaign";

export const ModalsContainer = () => {
  const { t } = useTranslation();

  const { isCampaignLink, handleNewsletter, newsletterResponse, userHasSubscribed, sourceName } =
    useCampaign("tablet");

  const [userSettingsModalProps] = useModal("userSettings");
  const [newsletterModalProps] = useModal("newsletterModal", true);

  const { close } = useModalActions();

  const [_, setUserState] = useUserContext();

  const saveCurrencyAndLanguage = (currencyId, currencyCode, language) => {
    setUserState((s) => ({
      ...s,
      user: {
        ...s.user,
        currencyId,
        currencyCode,
        language,
      },
    }));
  };

  const handleSettingsSave = useCallback<IUserSettingsModalProps["onSave"]>(
    async (language, currency) => {
      saveCurrencyAndLanguage(currency.id, currency.code, language);

      close("userSettings");
    },
    [close, saveCurrencyAndLanguage]
  );

  useDialogManager(["drawers", "modals"]);

  useListen(ModalEvents.USER_SETTINGS_SAVE, (data) => {
    saveCurrencyAndLanguage(data.currency.id, data.currency.code, data.language);
  });

  return (
    <>
      <UserSettingsModal
        {...userSettingsModalProps}
        baseUrl={env.NEXT_PUBLIC_BASE_URL}
        onSave={handleSettingsSave}
        defaultLang="en"
        autoChangeLang={true}
        autoPersist={true}
      />
      {isCampaignLink && !userHasSubscribed && (
        <NewsletterModal
          {...newsletterModalProps}
          response={newsletterResponse}
          onChange={handleNewsletter}
          terms={`${env.NEXT_PUBLIC_BASE_URL}terms`}
          privacyPolicy={`${env.NEXT_PUBLIC_BASE_URL}privacy-policy`}
          customContent={{
            title: t("landingpages:subscriptionTabletCampaign.title"),
            copy: t("landingpages:subscriptionTabletCampaign.copy"),
            source: sourceName,
          }}
        />
      )}
    </>
  );
};
