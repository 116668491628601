import { INewsletterFormValues, INewsletterResponse } from "@plum/plum-modals";
import { useEffect, useState } from "react";
import { segmentTrackEvent } from "@plum/plum-data";

export type Campaign = "tablet";

export const useCampaign = (campaignName: Campaign) => {
  const sourceName = `campaign_${campaignName}`;
  const [newsletterResponse, setNewsletterResponse] = useState<INewsletterResponse>();
  const [isCampaignLink, setIsCampaignLink] = useState(false);
  const [interactionStatus, setInteractionStatus] = useState<null | "subscribed" | "dismissed">(
    null
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setIsCampaignLink(searchParams.get("utm_source") === campaignName);
    const interactionStatus = window.localStorage.getItem(sourceName);
    if (interactionStatus === "subscribed" || interactionStatus === "dismissed") {
      setInteractionStatus(interactionStatus);
    }
  }, []);

  const handleNewsletter = async (values: INewsletterFormValues) => {
    const newsletterEndpoint = `promo/subscribe`;

    const rawResponse = await fetch(newsletterEndpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
      },
      body: JSON.stringify(values),
    });

    const response = await rawResponse.json();

    setNewsletterResponse({
      success: response.Ok,
      errors: response.errors,
    });

    if (response.Ok && !response.errors) {
      segmentTrackEvent({
        name: "newsletterSubscriptionCampaign",
        parameters: { source: campaignName },
      });

      window?.localStorage?.setItem(sourceName, "subscribed");
    }
  };
  return {
    isCampaignLink,
    handleNewsletter,
    newsletterResponse,
    userHasSubscribed: interactionStatus === "subscribed",
    sourceName,
  };
};
