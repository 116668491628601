import * as React from "react";

export const CondeNastReadersLogo = ({
  className,
  year = "2023",
}: {
  className?: string;
  year?: "2023" | "2024";
}) => {
  return (
    <svg
      className={className}
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="70" cy="70" r="70" fill="#070809" />
      <path
        d="M97.9644 71.6672L99.1851 72.1433L97.4456 75.6345L96.5178 75.25L97.9644 71.6672Z"
        fill="white"
      />
      <path
        d="M94.8547 73.48L93.7012 74.1636C93.4855 73.7892 93.28 73.5451 93.0847 73.4312C92.8813 73.3009 92.6188 73.2358 92.2974 73.2358C91.9027 73.2358 91.5751 73.3477 91.3147 73.5715C91.0543 73.7913 90.9241 74.068 90.9241 74.4016C90.9241 74.8614 91.2659 75.2317 91.9495 75.5125L92.8894 75.897C93.6544 76.2062 94.2139 76.5846 94.5679 77.0322C94.9219 77.4757 95.0989 78.021 95.0989 78.668C95.0989 79.5347 94.81 80.2508 94.2322 80.8164C93.6503 81.3861 92.9281 81.6709 92.0654 81.6709C91.2476 81.6709 90.5721 81.4288 90.0391 80.9446C89.5142 80.4604 89.1866 79.7788 89.0564 78.8999L90.4968 78.5825C90.5619 79.1359 90.6759 79.5184 90.8386 79.73C91.1316 80.1369 91.5588 80.3403 92.1204 80.3403C92.5639 80.3403 92.9321 80.1918 93.2251 79.8948C93.5181 79.5977 93.6646 79.2214 93.6646 78.7656C93.6646 78.5825 93.6381 78.4157 93.5852 78.2651C93.5364 78.1105 93.457 77.9701 93.3472 77.844C93.2414 77.7138 93.103 77.5938 92.9321 77.4839C92.7612 77.37 92.5578 77.2621 92.3218 77.1604L91.4124 76.782C90.1225 76.2367 89.4775 75.4392 89.4775 74.3894C89.4775 73.6814 89.7481 73.0894 90.2893 72.6133C90.8305 72.1331 91.5039 71.8931 92.3096 71.8931C93.396 71.8931 94.2444 72.422 94.8547 73.48Z"
        fill="white"
      />
      <path
        d="M85.0618 77.4839L87.9792 81.5H86.2397L83.5481 77.6426H83.2917V81.5H81.8696V72.0762H83.5359C84.781 72.0762 85.6803 72.3101 86.2336 72.7781C86.844 73.2989 87.1492 73.9866 87.1492 74.8411C87.1492 75.5084 86.9579 76.0821 86.5754 76.5623C86.193 77.0424 85.6884 77.3496 85.0618 77.4839ZM83.2917 76.4036H83.7434C85.0903 76.4036 85.7637 75.8888 85.7637 74.8594C85.7637 73.895 85.1086 73.4128 83.7983 73.4128H83.2917V76.4036Z"
        fill="white"
      />
      <path
        d="M79.877 73.4128H76.0989V75.6772H79.7671V77.0139H76.0989V80.1633H79.877V81.5H74.6768V72.0762H79.877V73.4128Z"
        fill="white"
      />
      <path
        d="M65.4697 81.5V72.0762H67.4473C68.3953 72.0762 69.144 72.1698 69.6934 72.3569C70.2834 72.54 70.8184 72.8513 71.2986 73.2908C72.2711 74.1778 72.7573 75.3436 72.7573 76.7881C72.7573 78.2367 72.2507 79.4085 71.2375 80.3037C70.7289 80.7513 70.1959 81.0626 69.6384 81.2375C69.1176 81.4125 68.3791 81.5 67.4229 81.5H65.4697ZM66.8918 80.1633H67.5327C68.1715 80.1633 68.7026 80.0962 69.1257 79.9619C69.5489 79.8195 69.9314 79.5937 70.2732 79.2844C70.9731 78.6456 71.323 77.8135 71.323 76.7881C71.323 75.7546 70.9771 74.9163 70.2854 74.2734C69.6628 73.6997 68.7453 73.4128 67.5327 73.4128H66.8918V80.1633Z"
        fill="white"
      />
      <path
        d="M61.6155 79.2112H57.5811L56.5312 81.5H54.9993L59.6624 71.4841L64.1606 81.5H62.6042L61.6155 79.2112ZM61.0356 77.8745L59.6379 74.6702L58.1731 77.8745H61.0356Z"
        fill="white"
      />
      <path
        d="M54.0869 73.4128H50.3088V75.6772H53.9771V77.0139H50.3088V80.1633H54.0869V81.5H48.8867V72.0762H54.0869V73.4128Z"
        fill="white"
      />
      <path
        d="M44.4221 77.4839L47.3396 81.5H45.6001L42.9084 77.6426H42.6521V81.5H41.23V72.0762H42.8962C44.1414 72.0762 45.0406 72.3101 45.594 72.7781C46.2043 73.2989 46.5095 73.9866 46.5095 74.8411C46.5095 75.5084 46.3183 76.0821 45.9358 76.5623C45.5533 77.0424 45.0487 77.3496 44.4221 77.4839ZM42.6521 76.4036H43.1038C44.4506 76.4036 45.124 75.8888 45.124 74.8594C45.124 73.895 44.4689 73.4128 43.1587 73.4128H42.6521V76.4036Z"
        fill="white"
      />
      <path
        d="M123.129 88.98L121.976 89.6636C121.76 89.2892 121.555 89.0451 121.359 88.9312C121.156 88.8009 120.894 88.7358 120.572 88.7358C120.177 88.7358 119.85 88.8477 119.589 89.0715C119.329 89.2913 119.199 89.568 119.199 89.9016C119.199 90.3614 119.541 90.7317 120.224 91.0125L121.164 91.397C121.929 91.7062 122.489 92.0846 122.843 92.5322C123.197 92.9757 123.374 93.521 123.374 94.168C123.374 95.0347 123.085 95.7508 122.507 96.3164C121.925 96.8861 121.203 97.1709 120.34 97.1709C119.522 97.1709 118.847 96.9288 118.314 96.4446C117.789 95.9604 117.461 95.2788 117.331 94.3999L118.772 94.0825C118.837 94.6359 118.951 95.0184 119.113 95.23C119.406 95.6369 119.834 95.8403 120.395 95.8403C120.839 95.8403 121.207 95.6918 121.5 95.3948C121.793 95.0977 121.939 94.7214 121.939 94.2656C121.939 94.0825 121.913 93.9157 121.86 93.7651C121.811 93.6105 121.732 93.4701 121.622 93.344C121.516 93.2138 121.378 93.0938 121.207 92.9839C121.036 92.87 120.832 92.7621 120.596 92.6604L119.687 92.282C118.397 91.7367 117.752 90.9392 117.752 89.8894C117.752 89.1814 118.023 88.5894 118.564 88.1133C119.105 87.6331 119.779 87.3931 120.584 87.3931C121.671 87.3931 122.519 87.922 123.129 88.98Z"
        fill="white"
      />
      <path
        d="M108.594 97V87.5762H110.572C111.52 87.5762 112.268 87.6698 112.818 87.8569C113.408 88.04 113.943 88.3513 114.423 88.7908C115.395 89.6778 115.882 90.8436 115.882 92.2881C115.882 93.7367 115.375 94.9085 114.362 95.8037C113.853 96.2513 113.32 96.5626 112.763 96.7375C112.242 96.9125 111.503 97 110.547 97H108.594ZM110.016 95.6633H110.657C111.296 95.6633 111.827 95.5962 112.25 95.4619C112.673 95.3195 113.056 95.0937 113.398 94.7844C114.097 94.1456 114.447 93.3135 114.447 92.2881C114.447 91.2546 114.101 90.4163 113.41 89.7734C112.787 89.1997 111.87 88.9128 110.657 88.9128H110.016V95.6633Z"
        fill="white"
      />
      <path
        d="M104.129 92.9839L107.047 97H105.307L102.616 93.1426H102.359V97H100.937V87.5762H102.604C103.849 87.5762 104.748 87.8101 105.301 88.2781C105.912 88.7989 106.217 89.4866 106.217 90.3411C106.217 91.0084 106.026 91.5821 105.643 92.0623C105.261 92.5424 104.756 92.8496 104.129 92.9839ZM102.359 91.9036H102.811C104.158 91.9036 104.831 91.3888 104.831 90.3594C104.831 89.395 104.176 88.9128 102.866 88.9128H102.359V91.9036Z"
        fill="white"
      />
      <path
        d="M97.0831 94.7112H93.0486L91.9988 97H90.4669L95.1299 86.9841L99.6282 97H98.0718L97.0831 94.7112ZM96.5032 93.3745L95.1055 90.1702L93.6407 93.3745H96.5032Z"
        fill="white"
      />
      <path
        d="M79.4349 87.5762L81.8885 93.875L84.452 87.1184L86.9056 93.875L89.5118 87.5762H91.056L86.8445 97.5981L84.4214 90.9209L81.8946 97.6042L77.8907 87.5762H79.4349Z"
        fill="white"
      />
      <path
        d="M75.8492 94.7112H71.8148L70.765 97H69.233L73.8961 86.9841L78.3943 97H76.838L75.8492 94.7112ZM75.2693 93.3745L73.8716 90.1702L72.4068 93.3745H75.2693Z"
        fill="white"
      />
      <path
        d="M64.8161 88.9128H61.038V91.1772H64.7062V92.5139H61.038V95.6633H64.8161V97H59.6159V87.5762H64.8161V88.9128Z"
        fill="white"
      />
      <path
        d="M57.6904 88.0828V89.7673C56.8684 89.0797 56.018 88.7358 55.1391 88.7358C54.1707 88.7358 53.3548 89.0837 52.6916 89.7795C52.0243 90.4713 51.6906 91.3176 51.6906 92.3186C51.6906 93.3074 52.0243 94.1415 52.6916 94.821C53.3589 95.5006 54.1768 95.8403 55.1452 95.8403C55.6457 95.8403 56.0709 95.759 56.4208 95.5962C56.6161 95.5148 56.8176 95.4049 57.0251 95.2666C57.2367 95.1283 57.4584 94.9614 57.6904 94.7661V96.4812C56.8766 96.941 56.0221 97.1709 55.1269 97.1709C53.78 97.1709 52.6306 96.7009 51.6784 95.761C50.7303 94.8129 50.2563 93.6695 50.2563 92.3308C50.2563 91.1305 50.653 90.0603 51.4465 89.1204C52.423 87.9688 53.6865 87.3931 55.2368 87.3931C56.0831 87.3931 56.901 87.623 57.6904 88.0828Z"
        fill="white"
      />
      <path d="M48.3369 87.5762V97H46.9147V87.5762H48.3369Z" fill="white" />
      <path
        d="M35.1503 92.2454C35.1503 90.9189 35.6366 89.7795 36.6091 88.8274C37.5775 87.8752 38.7412 87.3992 40.1003 87.3992C41.4431 87.3992 42.5946 87.8793 43.5549 88.8396C44.5192 89.7999 45.0014 90.9535 45.0014 92.3003C45.0014 93.6553 44.5172 94.8048 43.5488 95.7488C42.5763 96.6969 41.4024 97.1709 40.027 97.1709C38.8104 97.1709 37.7179 96.7498 36.7495 95.9075C35.6834 94.9757 35.1503 93.755 35.1503 92.2454ZM36.5847 92.2637C36.5847 93.3053 36.9346 94.1619 37.6345 94.8333C38.3303 95.5046 39.1339 95.8403 40.0453 95.8403C41.0341 95.8403 41.8683 95.4985 42.5478 94.8149C43.2273 94.1232 43.5671 93.2809 43.5671 92.2881C43.5671 91.283 43.2314 90.4408 42.56 89.7612C41.8927 89.0776 41.0667 88.7358 40.082 88.7358C39.1013 88.7358 38.2733 89.0776 37.5978 89.7612C36.9224 90.4367 36.5847 91.2708 36.5847 92.2637Z"
        fill="white"
      />
      <path
        d="M27.7499 91.2627H31.8149V87.5762H33.237V97H31.8149V92.5994H27.7499V97H26.3278V87.5762H27.7499V91.2627Z"
        fill="white"
      />
      <path
        d="M24.1337 88.0828V89.7673C23.3118 89.0797 22.4614 88.7358 21.5825 88.7358C20.614 88.7358 19.7982 89.0837 19.1349 89.7795C18.4676 90.4713 18.134 91.3176 18.134 92.3186C18.134 93.3074 18.4676 94.1415 19.1349 94.821C19.8023 95.5006 20.6201 95.8403 21.5886 95.8403C22.0891 95.8403 22.5143 95.759 22.8642 95.5962C23.0595 95.5148 23.2609 95.4049 23.4684 95.2666C23.68 95.1283 23.9018 94.9614 24.1337 94.7661V96.4812C23.3199 96.941 22.4654 97.1709 21.5703 97.1709C20.2234 97.1709 19.0739 96.7009 18.1218 95.761C17.1737 94.8129 16.6996 93.6695 16.6996 92.3308C16.6996 91.1305 17.0964 90.0603 17.8898 89.1204C18.8664 87.9688 20.1298 87.3931 21.6801 87.3931C22.5265 87.3931 23.3443 87.623 24.1337 88.0828Z"
        fill="white"
      />

      {year === "2024" ? (
        <path
          d="M93.8359 116.449H95.7578V118.434H93.8359V122H91.1055V118.434H83.2227L93.8359 103.559V116.449ZM91.1055 116.449V110.891L86.9336 116.449H91.1055Z"
          fill="white"
        />
      ) : (
        <path
          d="M91.3834 113.598V111.266C92.5397 111.266 93.317 111.137 93.7155 110.879C94.4264 110.426 94.7819 109.699 94.7819 108.699C94.7819 107.926 94.524 107.297 94.0084 106.812C93.5006 106.32 92.8405 106.074 92.028 106.074C91.2701 106.074 90.6842 106.258 90.2701 106.625C89.8717 107 89.5748 107.633 89.3795 108.523H86.6608C86.942 106.82 87.5201 105.574 88.3951 104.785C89.2701 103.988 90.4928 103.59 92.0631 103.59C93.649 103.59 94.942 104.059 95.942 104.996C96.942 105.926 97.442 107.125 97.442 108.594C97.442 110.25 96.7858 111.539 95.4733 112.461C96.9733 113.344 97.7233 114.777 97.7233 116.762C97.7233 118.387 97.1764 119.715 96.0826 120.746C94.9889 121.777 93.5865 122.293 91.8756 122.293C89.6959 122.293 88.0787 121.492 87.024 119.891C86.5865 119.219 86.3092 118.289 86.192 117.102H88.8287C88.9694 117.977 89.3092 118.648 89.8483 119.117C90.3951 119.578 91.1061 119.809 91.9811 119.809C92.8639 119.809 93.5944 119.516 94.1725 118.93C94.7506 118.328 95.0397 117.566 95.0397 116.645C95.0397 115.426 94.6061 114.559 93.7389 114.043C93.2311 113.746 92.4459 113.598 91.3834 113.598Z"
          fill="white"
        />
      )}
      <path
        d="M76.1317 119.434H82.87V122H70.7177L76.9989 114.395C77.6161 113.637 78.1317 112.98 78.5458 112.426C78.9677 111.871 79.288 111.406 79.5067 111.031C79.952 110.297 80.1747 109.637 80.1747 109.051C80.1747 108.207 79.8778 107.5 79.2841 106.93C78.6903 106.359 77.9559 106.074 77.0809 106.074C75.2528 106.074 74.2059 107.156 73.9403 109.32H71.2216C71.6591 105.508 73.5848 103.602 76.9989 103.602C78.6473 103.602 80.0302 104.125 81.1473 105.172C82.2723 106.219 82.8348 107.516 82.8348 109.062C82.8348 110.047 82.5731 111.012 82.0497 111.957C81.7841 112.449 81.3934 113.035 80.8778 113.715C80.37 114.387 79.7255 115.184 78.9442 116.105L76.1317 119.434Z"
        fill="white"
      />
      <path
        d="M56.087 112.836C56.087 109.953 56.7589 107.652 58.1027 105.934C59.3058 104.379 60.7628 103.602 62.4738 103.602C64.1847 103.602 65.6417 104.379 66.8448 105.934C68.1886 107.652 68.8605 109.992 68.8605 112.953C68.8605 115.906 68.1886 118.242 66.8448 119.961C65.6417 121.516 64.1886 122.293 62.4855 122.293C60.7823 122.293 59.3214 121.516 58.1027 119.961C56.7589 118.242 56.087 115.867 56.087 112.836ZM58.7706 112.848C58.7706 114.855 59.1183 116.523 59.8136 117.852C60.5089 119.156 61.3956 119.809 62.4738 119.809C63.5441 119.809 64.4308 119.156 65.1339 117.852C65.837 116.555 66.1886 114.918 66.1886 112.941C66.1886 110.965 65.837 109.324 65.1339 108.02C64.4308 106.723 63.5441 106.074 62.4738 106.074C61.4113 106.074 60.5284 106.723 59.8253 108.02C59.1222 109.309 58.7706 110.918 58.7706 112.848Z"
        fill="white"
      />
      <path
        d="M46.0267 119.434H52.765V122H40.6127L46.8939 114.395C47.5111 113.637 48.0267 112.98 48.4408 112.426C48.8627 111.871 49.183 111.406 49.4017 111.031C49.847 110.297 50.0697 109.637 50.0697 109.051C50.0697 108.207 49.7728 107.5 49.1791 106.93C48.5853 106.359 47.851 106.074 46.976 106.074C45.1478 106.074 44.101 107.156 43.8353 109.32H41.1166C41.5541 105.508 43.4799 103.602 46.8939 103.602C48.5424 103.602 49.9252 104.125 51.0424 105.172C52.1674 106.219 52.7299 107.516 52.7299 109.062C52.7299 110.047 52.4681 111.012 51.9447 111.957C51.6791 112.449 51.2885 113.035 50.7728 113.715C50.265 114.387 49.6205 115.184 48.8392 116.105L46.0267 119.434Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.088 31.3726L21.0906 34.4993H26.2605V64.6902H31.1442V34.4993H36.171V31.3657L21.088 31.3726Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 31.2847L21.0026 34.5873H26.1726V64.7782H31.2321V34.5873H36.2589V31.2778L21 31.2847ZM21.1768 31.4615L36.0821 31.4537V34.4113H31.0553V64.6023H26.3494V34.4113H21.1785L21.1768 31.4615Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7088 41.3391V39.9159H33.3833V64.6186H37.9236C37.9236 64.6186 37.9831 48.1737 37.9236 46.3935C37.852 44.2578 41.0692 44.1164 41.0692 44.1164H42.57V39.702C40.1394 39.3458 37.7088 41.3391 37.7088 41.3391Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7861 39.915L63.0912 55.7199L60.1715 39.8443H55.2307L60.3214 64.6893H64.1397L66.5352 52.3022L68.9307 39.915H65.7861Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.197 41.4107C75.197 41.4107 77.1273 41.4815 77.1989 45.042C77.2705 48.6007 77.1989 50.5224 77.1989 50.5224H73.1951V44.9704C73.1951 44.9704 73.1235 41.4107 75.197 41.4107ZM77.3421 54.5099V59.9912C77.3421 59.9912 77.2705 62.9807 75.3402 62.91C73.4098 62.8393 73.1951 59.9912 73.1951 59.9912V52.5166H82.2041C82.9192 39.0612 75.2686 39.5597 75.2686 39.5597C67.8328 40.4852 68.333 50.1671 68.333 50.1671C68.333 50.1671 67.4032 65.1879 75.4118 64.9033C83.4194 64.6187 82.1326 54.5099 82.1326 54.5099H77.3421Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.593 41.4107C101.593 41.4107 103.525 41.4815 103.595 45.042C103.666 48.6007 103.595 50.5224 103.595 50.5224H99.5906V44.9704C99.5906 44.9704 99.519 41.4107 101.593 41.4107ZM103.738 54.5099V59.9912C103.738 59.9912 103.666 62.9807 101.737 62.91C99.8054 62.8393 99.5906 59.9912 99.5906 59.9912V52.5166H108.6C109.316 39.0612 101.665 39.5597 101.665 39.5597C94.2283 40.4852 94.7286 50.1671 94.7286 50.1671C94.7286 50.1671 93.7996 65.1879 101.807 64.9033C109.816 64.6187 108.528 54.5099 108.528 54.5099H103.738Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.3 64.6903H87.6609V31.088H83.3V64.6903Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0902 64.6903H93.4512V31.088H89.0902V64.6903Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.05 41.0545V39.6313H109.724V64.6894H114.264C114.264 64.6894 114.247 47.8891 114.264 46.1089C114.285 43.9724 117.41 43.8309 117.41 43.8309H118.911V39.4174C116.481 39.0612 114.05 41.0545 114.05 41.0545Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3088 59.54C51.3088 59.54 51.1174 61.6532 49.4492 61.6756C47.6621 61.6998 47.2326 59.7781 47.2326 59.7781V53.7741C47.2326 53.7741 47.2809 52.9909 47.7328 52.4923C48.1865 51.9938 50.6654 50.6655 51.3088 50.0954V59.54ZM49.545 39.6304C42.1092 39.7727 43.1105 47.3068 43.1105 47.3068H47.5905V44.0456C47.5905 44.0456 47.901 41.6245 49.4734 41.6245C51.0466 41.6245 51.2373 43.9266 51.2373 43.9266V47.2491C51.0225 47.6286 49.545 48.6964 49.545 48.6964C48.9731 49.0759 48.3098 49.5813 46.5417 50.7613C44.3733 52.2086 43.4193 53.7508 43.4193 53.7508C42.0376 55.9338 42.4317 61.1185 43.1813 62.5546C43.4857 63.1368 44.6113 64.9757 47.2567 64.9757C49.9021 64.9757 51.5469 63.267 51.5469 63.267V64.6902H55.7646V47.3198C55.7646 39.2043 49.545 39.6304 49.545 39.6304Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6592 35.9941C41.2685 37.2706 40.3749 37.2706 40.2447 37.2706C39.3252 37.2706 38.9259 36.1096 38.9259 34.89C38.9259 34.0939 39.1165 32.2611 40.3663 32.2611C40.7484 32.2611 41.3728 32.4336 41.5902 33.441L42.7788 33.0666C42.7011 32.7311 42.1802 31.0803 40.4275 31.1001C38.8827 31.1001 37.5898 32.4051 37.5898 34.9478C37.5898 37.7217 39.1855 38.548 40.4016 38.548C41.3728 38.548 41.9887 38.0391 42.3622 37.5207C42.6744 37.0895 42.7529 36.7238 42.8391 36.3589L41.6592 35.9941Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4577 31.1872H40.4284C38.7577 31.1872 37.6778 32.6638 37.6778 34.9478C37.6778 38.2901 39.9462 38.46 40.4008 38.46C41.1814 38.46 41.817 38.1262 42.2897 37.469C42.57 37.0825 42.6537 36.7582 42.7348 36.4184L41.717 36.1044C41.3245 37.2663 40.4948 37.3585 40.2447 37.3585C39.2062 37.3585 38.8379 36.0294 38.8379 34.8909C38.8379 34.4363 38.9112 32.1731 40.3663 32.1731C40.7441 32.1731 41.4013 32.3257 41.654 33.3288L42.6718 33.008C42.564 32.619 42.0551 31.1872 40.4577 31.1872ZM40.4008 38.6351C39.9747 38.6351 39.1587 38.5333 38.4773 37.8502C37.8304 37.1998 37.5018 36.2235 37.5018 34.9478C37.5018 32.5569 38.6498 31.0112 40.4275 31.0112H40.4577C41.9835 31.0112 42.6882 32.2878 42.8641 33.0468L42.8831 33.1261L41.5238 33.554L41.5039 33.4591C41.2952 32.493 40.7018 32.349 40.3663 32.349C39.0794 32.349 39.0147 34.4656 39.0147 34.8909C39.0147 35.9483 39.3364 37.1835 40.2447 37.1835C40.5336 37.1835 41.24 37.0644 41.5747 35.969L41.6005 35.8845L42.9444 36.2994L42.915 36.421C42.8314 36.7755 42.7442 37.1421 42.4337 37.5716C42.0844 38.0572 41.4479 38.6351 40.4008 38.6351ZM44.6375 35.8603C44.6375 36.1864 44.6634 37.5595 45.5569 37.5595C46.4591 37.5595 46.4859 36.1579 46.4859 35.7163C46.4859 35.2549 46.3901 34.0654 45.5742 34.0654C44.8022 34.0654 44.6375 35.0358 44.6375 35.8603ZM47.6563 35.831C47.6563 37.7122 46.5894 38.4997 45.5742 38.4997C44.6461 38.4997 43.4662 37.8183 43.4662 35.8405C43.4662 34.1224 44.3511 33.2107 45.4534 33.2107C47.1975 33.2107 47.6563 34.6407 47.6563 35.831Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.7765 33.2209C49.9174 33.2209 49.3964 33.7678 49.1713 34.0076V33.3072H48V38.4133H49.1713V34.9098C49.5879 34.4871 49.9001 34.2568 50.2469 34.2568C50.6117 34.2568 50.6721 34.5639 50.6721 34.8434V38.4133H51.8434V34.5259C51.8434 33.6807 51.4967 33.2209 50.7765 33.2209Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3016 34.7943C55.155 34.6028 54.8772 34.2957 54.4865 34.2957C53.6188 34.2957 53.5757 35.505 53.5757 35.8888C53.5757 36.3114 53.6878 37.4241 54.4951 37.4241C54.9117 37.4241 55.1722 37.0981 55.3016 36.935V34.7943ZM56.4643 31.1371V38.4134H55.293V37.8269C55.1463 38.0003 54.7211 38.4997 54.0268 38.4997C52.9771 38.4997 52.3 37.3378 52.3 35.869C52.3 34.3915 52.9943 33.221 54.0354 33.221C54.2519 33.221 54.7815 33.259 55.293 33.892V31.138L56.4643 31.1371Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.3317 35.3126C58.3834 34.3725 58.8699 34.0939 59.2606 34.0939C59.5901 34.0939 60.1801 34.3052 60.2232 35.3126H58.3317ZM61.1435 34.6312C60.9089 33.8446 60.2577 33.221 59.252 33.221C57.9755 33.221 57.1 34.2474 57.1 35.8025C57.1 37.5302 58.1238 38.4997 59.2951 38.4997C60.5354 38.4997 61.0219 37.5112 61.178 37.1938L60.2232 36.7522C60.1197 36.9635 59.8937 37.4629 59.3037 37.4629C58.9656 37.4629 58.3757 37.2611 58.3317 36.0906H61.2643C61.2901 35.649 61.3074 35.1979 61.1435 34.6312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.4697 31.1855H59.0896L57.9364 32.6639H58.8128L60.4697 31.1855Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.1004 31.2432V36.0517H68.0831L65.4447 31.2432H64.1V38.4133H65.3679V33.4124H65.3843L68.109 38.4133H69.3674V31.2432H68.1004Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.8367 35.787C72.4012 35.8784 71.2514 36.1234 71.2514 36.923C71.2514 37.3327 71.5386 37.5319 71.8095 37.5319C72.2287 37.5319 72.672 37.1412 72.8367 36.9963V35.787ZM73.889 37.2869C73.889 37.5776 73.9701 38.0503 74.0443 38.2771H72.9773C72.9346 38.1124 72.9015 37.9454 72.8781 37.7769C72.6729 37.9778 72.2787 38.3591 71.5973 38.3591C70.8659 38.3591 70.2 37.8778 70.2 37.023C70.2 35.7603 71.4576 35.2152 72.8376 35.097V34.9504C72.8376 34.4786 72.5331 34.1879 72.0984 34.1879C71.6128 34.1879 71.2523 34.4605 70.9806 34.8607L70.3233 34.2517C70.5282 33.97 70.7981 33.742 71.11 33.5869C71.4218 33.4317 71.7665 33.3542 72.1148 33.3607C72.8704 33.3607 73.889 33.6333 73.889 35.0237V37.2869Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9348 35.36C76.4569 35.2453 76.0067 35.1401 76.0067 34.689C76.0067 34.3914 76.2749 34.0938 76.7001 34.0938C77.0038 34.0938 77.2384 34.2189 77.6291 34.4871L77.7412 34.5648L78.4183 33.9403C78.0802 33.6617 77.5333 33.2201 76.6915 33.2201C75.6591 33.2201 74.913 33.9403 74.913 34.8037C74.913 36.0612 76.0757 36.3304 76.5785 36.4261C77.0469 36.5123 77.4376 36.6469 77.4376 37.0402C77.4376 37.4146 77.0469 37.5491 76.7088 37.5491C76.1361 37.5491 75.7721 37.242 75.5116 37.0212L74.8 37.5681C75.095 37.914 75.616 38.4996 76.6915 38.4996C78.1233 38.4996 78.6089 37.5681 78.6089 36.8582C78.6089 35.7447 77.5506 35.5032 76.9348 35.36Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.2738 34.238V33.3064H80.4578V31.6573H79.2865V33.3073H78.5835V34.238H79.2865V36.9549C79.2865 37.4629 79.4599 37.9623 79.8678 38.2599C80.1973 38.5092 80.6139 38.5187 81.2738 38.4902V37.4345C80.8313 37.425 80.4578 37.3957 80.4578 36.9541V34.2371L81.2738 34.238Z"
        fill="white"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70 135.5C106.175 135.5 135.5 106.175 135.5 70C135.5 33.8253 106.175 4.5 70 4.5C33.8253 4.5 4.5 33.8253 4.5 70C4.5 106.175 33.8253 135.5 70 135.5ZM70 136C106.451 136 136 106.451 136 70C136 33.5492 106.451 4 70 4C33.5492 4 4 33.5492 4 70C4 106.451 33.5492 136 70 136Z"
        fill="white"
      />
    </svg>
  );
};
