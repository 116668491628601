import * as React from "react";
import { useModal, useModalActions } from "@plum/plum-modals";
import { ILoginConfig, HEADER_EVENTS, AuthFormModal } from "@plum/plum-nav";
import { useLoginHandlers } from "@plum/plum-nav/hooks/useLoginHandlers";
import { IUserInfo, identityService } from "@plum/plum-nav/services";
import { useDrawer, useDrawerActions, Drawer } from "@plum/plum-ui-next";
import { useEventListener } from "@plum/ui-hooks";
import { AccountButton } from "./AccountButton";
import { LoggedInTopBar } from "./LoggedInTopBar";
import { LoggedInContent } from "./LoggedInContent";
import { useLoginActionsContext, useLoginStateContext } from "./LoginContext";

export interface ILoginMenuProps {
  config: ILoginConfig;
  currentPathname?: string;
  isTransparent?: boolean;
  token?: string;
  theme?: "default" | "cream" | "transparent";
  onLogin?: (user: IUserInfo) => void;
  onLogout?: () => void;
  shouldRedirectOnLogout?: boolean;
}

export const LoginMenu: React.FC<ILoginMenuProps> = ({
  config,
  token,
  currentPathname = "",
  theme = "default",
  onLogin,
  onLogout,
  shouldRedirectOnLogout,
}) => {
  const [drawerProps] = useDrawer("loginMenu");
  const [authFormModalProps] = useModal("authFormModal");

  const drawerActions = useDrawerActions();
  const modalActions = useModalActions();

  const { isLoggedIn } = useLoginStateContext();
  const { performLogin, performRegister, performLogout } = useLoginActionsContext();

  const callBacks = useLoginHandlers({
    onLogin: performLogin,
    onRegister: performRegister,
    onGetUser: onLogin,
    onLogoutConsumingApp: onLogout,
    onLogout: performLogout,
    onClose: drawerActions.close,
    shouldRedirectOnLogout,
  });

  useEventListener(HEADER_EVENTS.HEADER_EVENT_LOGIN_TOGGLE, () => {
    !isLoggedIn ? modalActions.toggle("authFormModal") : drawerActions.toggle("loginMenu");
  });

  React.useEffect(() => {
    const isAuthenticated = Boolean(config.userInfo?.isAuthenticated);

    if (isAuthenticated) {
      performLogin();
    }
  }, [config.userInfo, performLogin]);

  React.useEffect(() => {
    if (config.autoFetchUserInfo) {
      // Use the getUserInfo API to figure out if they are logged in or not
      identityService
        .getUserInfo(config.identityBaseUrl)
        .then((data) => {
          if (data && data.isAuthenticated) performLogin();
          config.onUserFetched && config.onUserFetched(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [config, config.autoFetchUserInfo, config.identityBaseUrl, performLogin]);

  const { userInfo } = config;

  return (
    <>
      <AccountButton
        cdnUrl={config.cdnUrl}
        // isLink={!token && !isLoggedIn}
        isLoggedIn={isLoggedIn}
        theme={theme}
        userInfo={config.userInfo}
        onClick={() => {
          !isLoggedIn ? modalActions.open("authFormModal") : drawerActions.open("loginMenu");
        }}
      />
      <Drawer
        placeholder=""
        {...drawerProps}
        hasSeparator
        header={
          <LoggedInTopBar name={userInfo?.name} src={userInfo?.photoUrl} cdnUrl={config.cdnUrl} />
        }
      >
        <React.Suspense fallback={null}>
          <LoggedInContent
            currentPathname={currentPathname}
            user={config.userInfo}
            onLogout={callBacks.handleLogout}
          />
        </React.Suspense>
      </Drawer>

      <AuthFormModal
        config={{ facebookAppId: config?.facebookAppId, googleAppId: config?.googleAppId }}
        token={token || ""}
        onClose={modalActions.close}
        scopedCallBacks={callBacks}
        onPointerDownOutside={handle1PasswordClick}
        {...authFormModalProps}
      />
    </>
  );
};

export const handle1PasswordClick = (e: Event) => {
  if (e.target instanceof Element && e.target.nodeName?.toLowerCase().includes("1password")) {
    // 1Password button is a closed shadow dom element, so we have to read the nodeName for identification purposes
    // If 1Password is the parent node, we prevent the auth modal from closing when the 1Password button is clicked
    e.preventDefault();
  }
};
